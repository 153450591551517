// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

const signInConfig = {
  WEB_PROTOCOL:'https://',
  // CHANGE ACCORDINGLY FOR EVERY PROJECT
  // **** START ****
  // DOMAIN_NAME: 'hht-web-dev.auth.ap-southeast-1.amazoncognito.com',
  // CLIENT_ID: 'sk05k323vmeiq342m96bfk75h',
  // RESPONSE_TYPE: 'token',  // or 'token', note that REFRESH token will only be generated when the responseType is code
  // REDIRECT_SIGN_IN: 'http://localhost:4200/login',
  // REDIRECT_SIGN_OUT: 'http://localhost:4200/login',
  // SCOPE: ['email', 'openid'],
  // ENV: 'dev',
  // **** END ****

  // DOMAIN_NAME: 'hht-web-test.auth.ap-southeast-1.amazoncognito.com',
  // CLIENT_ID: '3sldhokv37nk50g9qsu4jmt41m',
  // RESPONSE_TYPE: 'token',  // or 'token', note that REFRESH token will only be generated when the responseType is code
  // REDIRECT_SIGN_IN: 'https://portal-uat-hht.meco-soldel.com/login',
  // REDIRECT_SIGN_OUT: 'https://portal-uat-hht.meco-soldel.com/login',
  // SCOPE: ['email', 'openid'],
  // ENV: 'uat',

  // DOMAIN_NAME: 'hht-web-sit.auth.ap-southeast-1.amazoncognito.com',
  // CLIENT_ID: '7puks1r3spsavafmtfu62vjor2',
  // RESPONSE_TYPE: 'token',  // or 'token', note that REFRESH token will only be generated when the responseType is code
  // REDIRECT_SIGN_IN: 'https://portal-sit-hht.meco-soldel.com/login',
  // REDIRECT_SIGN_OUT: 'https://portal-sit-hht.meco-soldel.com/login',
  // SCOPE: ['email', 'openid'],
  // ENV: 'sit',

  DOMAIN_NAME: 'hht-web-sqt.auth.ap-southeast-1.amazoncognito.com',
  CLIENT_ID: '741bv314p1o7hhp3m4ndh39cs8',
  RESPONSE_TYPE: 'token',  // or 'token', note that REFRESH token will only be generated when the responseType is code
  REDIRECT_SIGN_IN: 'https://portal-sqt-hht.meco-soldel.com/login',
  REDIRECT_SIGN_OUT: 'https://portal-sqt-hht.meco-soldel.com/login',
  SCOPE: ['email', 'openid'],
  ENV: 'sqt',


}

export const environment = {
  name: `${signInConfig.ENV}`,
  production: false,

  app_version : 'v2023.1.0.0_SQT',
  // CHANGE ACCORDINGLY FOR EVERY PROJECT
  // **** START ****
  homepage: '/sample',
  //hostURL: 'https://4tffqdueif.execute-api.ap-southeast-1.amazonaws.com/dev',
  //hostURL: 'https://api-uat-hht.meco-soldel.com/dev',
  //hostURL: 'https://api-sit-hht.meco-soldel.com/dev',
  hostURL: 'https://api-sqt-hht.meco-soldel.com/dev',

  // **** END ****

  // AMPLIFY environments
  domainName: `${signInConfig.DOMAIN_NAME}`,
  clientId: `${signInConfig.CLIENT_ID}`,
  responseType: `${signInConfig.RESPONSE_TYPE}`,
  redirectUri: `${signInConfig.REDIRECT_SIGN_IN}`,
  redirectSignIn: `${signInConfig.REDIRECT_SIGN_IN}`,
  redirectSignOut: `${signInConfig.REDIRECT_SIGN_OUT}`,
  scope: `${signInConfig.SCOPE}`,
  signIn: `${signInConfig.WEB_PROTOCOL}${signInConfig.DOMAIN_NAME}/login?client_id=${signInConfig.CLIENT_ID}&response_type=${signInConfig.RESPONSE_TYPE}&scope=email+openid+phone+aws.cognito.signin.user.admin&redirect_uri=${signInConfig.REDIRECT_SIGN_IN}`,
  signOut: `${signInConfig.WEB_PROTOCOL}${signInConfig.DOMAIN_NAME}/login?client_id=${signInConfig.CLIENT_ID}&response_type=${signInConfig.RESPONSE_TYPE}&scope=email+openid+phone+aws.cognito.signin.user.admin&redirect_uri=${signInConfig.REDIRECT_SIGN_OUT}`,
  
  //signIn: `${signInConfig.WEB_PROTOCOL}${signInConfig.DOMAIN_NAME}/login?client_id=${signInConfig.CLIENT_ID}&response_type=${signInConfig.RESPONSE_TYPE}&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=${signInConfig.REDIRECT_SIGN_IN}`,
  //signOut: `${signInConfig.WEB_PROTOCOL}${signInConfig.DOMAIN_NAME}/logout?response_type=code&client_id=${signInConfig.CLIENT_ID}&redirect_uri=${signInConfig.REDIRECT_SIGN_OUT}`,
  //signOut: `${signInConfig.WEB_PROTOCOL}${signInConfig.DOMAIN_NAME}/logout?client_id=${signInConfig.CLIENT_ID}&logout_uri=${signInConfig.REDIRECT_SIGN_OUT}`,
  adPrefix: `MeralcoDev_`
};